/* Core CSS required for Ionic components to work properly */
@import './theme/variables.scss';
@import './theme/fonts.scss';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

@import "./flags.css";

@layer primeng;

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 32px;
  --padding-top: 32px;
  &.triangle-background::part(background) {
    &::before {
      content: '';
      width: 100%;
      height: 432px;
      background: var(--ion-color-primary);
      position: fixed;
      clip-path: polygon(0 0, 100% 0%, 100% 78%, 0% 100%);
    }
  }
  &.triangle-image {
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-bottom: 32px;
    --padding-top: 0px;
    img:first-child {
      clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 100%);
      width: 100%;
    }
  }
}

h1 {
  @include textStyleH1();
}

h2 {
  @include textStyleH2();
}

h3 {
  @include textStyleH3();
  margin-top: 16px;
  margin-bottom: 12px;
}

hr {
  margin: 16px 0px;
  background-color: var(--ion-color-secondary);
}

// TODO: FIX ME?
.sc-ion-action-sheet-ios-h {
  --button-color: var(--ion-color-dark);
}

ion-input.input-fill-solid.sc-ion-input-md-h, ion-textarea.textarea-fill-solid.sc-ion-textarea-md-h {
  --background: var(--ion-color-secondary);
  --border-radius: 0px;
}

ion-input .label-text.sc-ion-input-md,
.sc-ion-input-md-s > [slot='label'] {
  line-height: 19px;
}

ion-select {
  --background: var(--ion-color-secondary);
  --padding-start: 8px;
  --padding-end: 8px;
  --border-radius: 0px;
}

ion-checkbox {
  --size: 24px;
  --checkbox-background-checked: var(--ion-color-light);
  --checkmark-color: var(--ion-color-success);
}

.login-modal .p-dialog-header {
  background: var(--ion-color-primary);
}
.login-modal .p-dialog-content {
  padding: 0px !important;
}