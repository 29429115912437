@font-face {
  font-family: 'Bree';
  src: url('../assets/fonts/bree/Bree-Regular.otf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Bree';
  src: url('../assets/fonts/bree/Bree-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Impact';
  src: url('../assets/fonts/impact/Impact.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
