// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Poppins';

  --ion-color-primary: #ffd547;
  --ion-color-primary-rgb: 255, 213, 71;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0bb3e;
  --ion-color-primary-tint: #ffd959;

  --ion-color-secondary: #f1efe7;
  --ion-color-secondary-rgb: 241, 239, 231;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d4d2cb;
  --ion-color-secondary-tint: #f2f1e9;

  --ion-color-success: #458900;
  --ion-color-success-rgb: 69, 137, 0;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #3d7900;
  --ion-color-success-tint: #58951a;

  --ion-color-danger: #ff0003;
  --ion-color-danger-rgb: 255, 0, 3;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e00003;
  --ion-color-danger-tint: #ff1a1c;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
}

@mixin textStyleH1() {
  font-size: 32px;
  font-family: 'Impact';
  font-weight: 400;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--ion-color-light);
}
@mixin textStyleH2() {
  font-size: 24px;
  font-family: 'Impact';
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin textStyleH3() {
  font-size: 20px;
  font-family: 'Impact';
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
}

// @mixin textStyleButtonXl() {
// 	font-size: 18px;
// 	font-family: "Poppins";
// 	font-weight: 800;
// 	font-style: normal;
// 	line-height: 24px;
// 	letter-spacing: 0.1em;
// 	text-decoration: none;
// 	text-transform: none;
// }
// @mixin textStyleButtonM() {
// 	font-size: 14px;
// 	font-family: "Poppins";
// 	font-weight: 600;
// 	font-style: normal;
// 	line-height: 16px;
// 	letter-spacing: 0.1em;
// 	text-decoration: none;
// 	text-transform: none;
// }

@mixin textStyleSmall() {
  font-size: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-decoration: none;
  text-transform: none;
}

@mixin textStylePlainText() {
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: none;
}
@mixin textStyleS() {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin textStyleDestacadoMedio() {
  font-size: 16px;
  font-family: 'Bree';
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin textStyleDestacadoL() {
  font-size: 28px;
  font-family: 'Bree';
  font-weight: 700;
  font-style: normal;
  line-height: 32px;
  text-decoration: none;
  text-transform: none;
}
@mixin textStyleDestacadoM() {
  font-size: 22px;
  font-family: 'Bree';
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  text-transform: none;
}
@mixin effectStyleMarocShadow1() {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
